import { getOrCreateDataObject } from 'o365.vue.ts';

export default getOrCreateDataObject({
    appId: "menu",
    id:'dsTBookmarks',
    viewName: 'sviw_System_Bookmarks',
    distinctRows: true,
    maxRecords: 100,    
    disableLayouts: true,
    fields:[
        {
            name: 'HostName',
            type: 'string'
        },
        {
            name: 'IsNT',
            type: 'bit'
        },
        {
            name: 'ArticleID',
            type: 'string'
        },
        {
            name: 'BookmarkName',
            type: 'string'
        },
        {
            name: 'Title',
            type: 'string'
        },
        {
            name: 'QueryString',
            type: 'string'
        },
        {
            name: 'Name',
            type: 'string'
        },
        {
            name: 'Report_ID',
            type: 'number'
        },
        {
            name: 'MenuGroup',
            type: 'string',
            sortDirection: 'asc',
            sortOrder: 1
        },
        {
            name: 'ID',
            type: 'number'
        }
    ]
});